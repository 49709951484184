<table *ngIf="currentMostConfidentDocument">
  <tr>
    <th>{{ 'classification' | translate }}</th>
    <td></td>
    <th class="close-cell">
      <fa-icon
        class="close-icon"
        (click)="closeResults()"
        [icon]="faXmark"
      ></fa-icon>
    </th>
  </tr>
  <tr>
    <td>{{ currentMostConfidentDocument.classifyInfo.documentType }}</td>
    <td>{{ currentMostConfidentDocument.classifyInfo.confidence * 100 | number: '1.2-2' }}%</td>
  </tr>
  <tr>
    <th>{{ 'extraction' | translate }}</th>
  </tr>
  <tr *ngFor="let keyField of currentMostConfidentDocument.extractInfo.keyFields">
    <td>{{ keyField.key }}</td>
    <td>{{ keyField.value }}</td>
    <td>{{ keyField.confidence * 100 | number: '1.2-2' }}%</td>
  </tr>
  <tr *ngIf="currentMostConfidentDocument.extractInfo.keyFields.length < 1">
    <td>None</td>
  </tr>
</table>
<div *ngIf="!currentMostConfidentDocument">
  <span class="no-results-span">{{ 'noExtractionResults' | translate }}</span>
  <fa-icon
    class="close-icon"
    (click)="closeResults()"
    [icon]="faXmark"
  ></fa-icon>
</div>
