"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useBetweenNumericOperation = exports.useLesserThanEqualOperation = exports.useLesserThanOperation = exports.useGreaterThanEqualOperation = exports.useGreaterThanOperation = exports.useEqualsOperation = void 0;
function useEqualsOperation(payload) {
    return (attribute) => ({ isValid: attribute === payload.number });
}
exports.useEqualsOperation = useEqualsOperation;
function useGreaterThanOperation(payload) {
    return (attribute) => ({ isValid: attribute > payload.number });
}
exports.useGreaterThanOperation = useGreaterThanOperation;
function useGreaterThanEqualOperation(payload) {
    return (attribute) => ({ isValid: attribute >= payload.number });
}
exports.useGreaterThanEqualOperation = useGreaterThanEqualOperation;
function useLesserThanOperation(payload) {
    return (attribute) => ({ isValid: attribute < payload.number });
}
exports.useLesserThanOperation = useLesserThanOperation;
function useLesserThanEqualOperation(payload) {
    return (attribute) => ({ isValid: attribute <= payload.number });
}
exports.useLesserThanEqualOperation = useLesserThanEqualOperation;
function useBetweenNumericOperation(payload) {
    return (attribute) => ({ isValid: attribute >= payload.start && attribute <= payload.end });
}
exports.useBetweenNumericOperation = useBetweenNumericOperation;
