<div class="batch-settings">
  <span class="batch-settings-title">
    {{ 'batchSettings' | translate }}
  </span>
  <form
    class="batch-form"
    [formGroup]="batchFormGroup"
  >
    <label>
      {{ 'batchId' | translate }}
      <input
        type="text"
        [placeholder]="'batchId' | translate"
        formControlName="batchId"
      />
    </label>
    <label>
      {{ 'batchName' | translate }}
      <input
        type="text"
        [placeholder]="'batchName' | translate"
        formControlName="name"
      />
    </label>
    <label htmlFor="areaAccess">
      {{ 'areaAccess' | translate }}
      <div class="select-container">
        <p-multiSelect
          [options]="areas"
          formControlName="areas"
          optionLabel="name"
          display="chip"
          [showClear]="false"
        />
      </div>
    </label>
    <label>
      {{ 'defaultDocumentType' | translate }}
      <div class="select-container">
        <select formControlName="defaultDocumentType">
          <option
            *ngFor="let documentType of filteredDocumentTypes"
            [value]="documentType.code"
          >
            {{ documentType.name }}
          </option>
        </select>
      </div>
    </label>
  </form>
</div>
