import { FieldValue, LookupFieldValue } from '../models/etrieveContent';
import { DocumentType } from '../services/content-api/content-api.service';

/**
 * Converts a Base64 encoded string to a File object
 *
 * @param {string} base64String - The Base64 encoded string representing the file's content
 * @param {string} fileName - The desired name for the resulting File object
 * @param {string} contentType - The MIME type of the file
 * @returns {File} - The resulting File object
 */
export const base64ToFile = (base64String: string, fileName: string, contentType: string): File => {
  // Decode the Base64 string into binary data
  const binaryData = atob(base64String);

  // Create an array buffer from the binary string
  const byteArray = new Uint8Array(binaryData.length);
  for (let i = 0; i < binaryData.length; i++) {
    byteArray[i] = binaryData.charCodeAt(i);
  }

  // Create a Blob from the array buffer
  const blob = new Blob([byteArray], { type: contentType });

  // Create a File object from the Blob with the specified file name
  const file = new File([blob], fileName, { type: contentType });

  return file;
};

/**
 * Type guard function to determine if a value is of type LookupFieldValue
 *
 * @param {any} value - The value to check
 * @returns {boolean} - True if the value is a LookupFieldValue, false otherwise
 */
const isLookupField = (value: any): value is LookupFieldValue => {
  return value && typeof value === 'object' && 'lookupTypeCode' in value;
};

/**
 * Builds an array of FieldValue objects based on the provided form data and document type
 *
 * @param {any} formData - The form data containing the field values
 * @param {DocumentType} documentType - The document type definition containing key fields
 * @returns {FieldValue[]} - An array of FieldValue objects representing the key fields
 */
export const buildKeyFields = (formData: any, documentType: DocumentType): FieldValue[] => {
  const fieldValues: FieldValue[] = [];

  for (const [key, value] of Object.entries(formData)) {
    if (key !== 'documentTypeCode' && key !== 'area' && value) {
      if (Array.isArray(value)) {
        // If the value is an array, push each item as a separate fieldValue
        value.forEach(item => {
          fieldValues.push({
            fieldCode: key,
            value: item
          });
        });
      } else if (isLookupField(value)) {
        // If the field is a lookup field value, build field values only for lookup unique identifier fields
        const lookupFieldDefinition = documentType.keyfields.find(field => field.code === key);
        if (lookupFieldDefinition && lookupFieldDefinition.lookupType) {
          lookupFieldDefinition.lookupType.keyfields.forEach(field => {
            if (field.isUniqueIdentifier) {
              const fieldValue = value.fieldValues.find(fv => fv.fieldCode === field.code);
              if (fieldValue) {
                fieldValues.push({
                  fieldCode: field.code,
                  value: fieldValue.value,
                  parentFieldCode: lookupFieldDefinition.code
                });
              }
            }
          });
        }
      } else {
        // If the value is not an array and not a lookup field, push it as is
        fieldValues.push({
          fieldCode: key,
          value: value
        });
      }
    }
  }

  return fieldValues;
};
