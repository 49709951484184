export const environment = {
  production: false,
  frontegg: {
    moduleConfig: {
      contextOptions: {
        baseUrl: 'https://login.softdocsdev.cloud',
        clientId: 'af19eb4a-0f2a-4de6-8f08-620ca3ac673c',
        appId: 'bff95bbe-e049-4985-a116-b9f23d0c381a'
      },
      hostedLoginBox: true,
      authOptions: { keepSessionAlive: true }
    }
  },
  urls: {
    autoclassifierApi: 'https://api.softdocsdev.cloud/autoclassifier',
    tenantApi: 'https://api.softdocsdev.cloud/tenant'
  },
  launchDarkly: {
    clientSideId: '64b69036d75e0613f8d7443c'
  }
};
