"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.createRuleEvaluator = void 0;
const types_1 = require("./types");
const conditions_1 = require("../conditions");
function createRuleEvaluator(payload) {
    return (attributes) => {
        const isRuleTreatable = payload.rule.conditions.every((condition) => {
            const evaluator = (0, conditions_1.createConditionEvaluator)({ condition });
            return evaluator(attributes);
        });
        return isRuleTreatable ? types_1.RuleEvaluationResultEnum.Treatable : types_1.RuleEvaluationResultEnum.Insufficient;
    };
}
exports.createRuleEvaluator = createRuleEvaluator;
