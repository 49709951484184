"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.NotEntitledJustification = exports.createPermissionCheckRegex = exports.checkPermission = exports.prepareAttributes = exports.evaluateIsEntitledToPermissions = exports.evaluateIsEntitledToFeature = exports.OperationEnum = exports.TreatmentEnum = exports.evaluatePlan = exports.evaluateFeatureFlag = void 0;
var feature_flags_1 = require("./feature-flags");
Object.defineProperty(exports, "evaluateFeatureFlag", { enumerable: true, get: function () { return feature_flags_1.evaluateFeatureFlag; } });
var plans_1 = require("./plans");
Object.defineProperty(exports, "evaluatePlan", { enumerable: true, get: function () { return plans_1.evaluatePlan; } });
var rules_1 = require("./rules");
Object.defineProperty(exports, "TreatmentEnum", { enumerable: true, get: function () { return rules_1.TreatmentEnum; } });
var types_1 = require("./operations/types");
Object.defineProperty(exports, "OperationEnum", { enumerable: true, get: function () { return types_1.OperationEnum; } });
var user_entitlements_1 = require("./user-entitlements");
Object.defineProperty(exports, "evaluateIsEntitledToFeature", { enumerable: true, get: function () { return user_entitlements_1.evaluateIsEntitledToFeature; } });
Object.defineProperty(exports, "evaluateIsEntitledToPermissions", { enumerable: true, get: function () { return user_entitlements_1.evaluateIsEntitledToPermissions; } });
Object.defineProperty(exports, "prepareAttributes", { enumerable: true, get: function () { return user_entitlements_1.prepareAttributes; } });
Object.defineProperty(exports, "checkPermission", { enumerable: true, get: function () { return user_entitlements_1.checkPermission; } });
Object.defineProperty(exports, "createPermissionCheckRegex", { enumerable: true, get: function () { return user_entitlements_1.createPermissionCheckRegex; } });
Object.defineProperty(exports, "NotEntitledJustification", { enumerable: true, get: function () { return user_entitlements_1.NotEntitledJustification; } });
