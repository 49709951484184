"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DateSanitizersMapper = exports.sanitizeDateRange = exports.sanitizeSingleDate = exports.isValidDate = exports.sanitizeDateValue = void 0;
const types_1 = require("../types");
const sanitizeDateValue = (value) => {
    return new Date(value);
};
exports.sanitizeDateValue = sanitizeDateValue;
const isValidDate = (value) => value instanceof Date && !isNaN(value);
exports.isValidDate = isValidDate;
const sanitizeSingleDate = (value) => {
    const sanitizedDateValue = value.date ? (0, exports.sanitizeDateValue)(value.date) : undefined;
    const sanitizedValue = sanitizedDateValue && (0, exports.isValidDate)(sanitizedDateValue) ? { date: sanitizedDateValue } : undefined;
    return {
        isSanitized: !!sanitizedValue,
        sanitizedValue,
    };
};
exports.sanitizeSingleDate = sanitizeSingleDate;
const sanitizeDateRange = (value) => {
    const sanitizedStartValue = value.start ? (0, exports.sanitizeDateValue)(value.start) : undefined;
    const sanitizedEndValue = value.end ? (0, exports.sanitizeDateValue)(value.end) : undefined;
    const sanitizedValue = sanitizedStartValue && sanitizedEndValue && (0, exports.isValidDate)(sanitizedStartValue) && (0, exports.isValidDate)(sanitizedEndValue)
        ? { start: sanitizedStartValue, end: sanitizedEndValue }
        : undefined;
    return {
        isSanitized: !!sanitizedValue,
        sanitizedValue,
    };
};
exports.sanitizeDateRange = sanitizeDateRange;
exports.DateSanitizersMapper = {
    [types_1.OperationEnum.On]: exports.sanitizeSingleDate,
    [types_1.OperationEnum.OnOrAfter]: exports.sanitizeSingleDate,
    [types_1.OperationEnum.OnOrBefore]: exports.sanitizeSingleDate,
    [types_1.OperationEnum.BetweenDate]: exports.sanitizeDateRange,
};
