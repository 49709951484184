import { Injectable } from '@angular/core';
import { AuthService } from '../services/auth/auth.service';
import { HttpContextToken, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';

export const DISABLE_AUTH_INTERCEPTOR = new HttpContextToken<boolean>(() => false);

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(private authService: AuthService) {}

  /**
   *
   * @param req an http request
   * @param next the next handler that the request should be passed to
   */
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<any> {
    // Check if the request should be skipped by interceptor
    if (req.context.get(DISABLE_AUTH_INTERCEPTOR)) {
      return next.handle(req);
    }

    // Get auth token from auth service
    const authToken = this.authService.getAuthToken();

    // Clone the request and add auth header
    if (authToken) {
      req = req.clone({
        setHeaders: {
          Authorization: `Bearer ${authToken}`
        }
      });
    }

    return next.handle(req);
  }
}
