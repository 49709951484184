import { HttpClient } from '@angular/common/http';
import { jwtDecode } from 'jwt-decode';
import { Injectable } from '@angular/core';

// Define the interface that matches the decoded token structure
export interface DecodedToken {
  tenantId: string;
  name: string;
  customClaims: {
    principal_id: string;
  };
}

export interface AuthPayload {
  access_token: string;
  refresh_token: string;
  expires_in: number;
  expires: string;
}

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private authToken: string | null = null;

  constructor(private http: HttpClient) {}

  /**
   * Extracts tenantId from the decoded JWT token
   * @param authToken user's auth token
   * @returns tenantId as string
   */
  extractTenantId(authToken: string): string {
    const decodedToken: DecodedToken = jwtDecode<DecodedToken>(authToken);
    return decodedToken.tenantId;
  }

  /**
   * Extracts user info from the decoded JWT token
   * @param authToken user's auth token
   * @returns object containing key, name, and tenantId
   */
  extractUserInfo(authToken: string): { key: string; name: string; tenantId: string } {
    const decodedToken: DecodedToken = jwtDecode<DecodedToken>(authToken);

    const key = decodedToken.customClaims.principal_id;
    const name = decodedToken.name;
    const tenantId = decodedToken.tenantId;

    return { key, name, tenantId };
  }

  /**
   * Sets the auth token
   * @param token a bearer token
   */
  setAuthToken(token: string): void {
    this.authToken = token;
  }

  /**
   * Returns the auth token
   * @returns the auth token or null
   */
  getAuthToken(): string | null {
    return this.authToken;
  }
}
