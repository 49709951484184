"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useDateBetweenOperation = exports.useDateOnOrBeforeOperation = exports.useDateOnOrAfterOperation = exports.useDateOnOperation = void 0;
function useDateOnOperation(payload) {
    return (attribute) => ({ isValid: attribute.getTime() === payload.date.getTime() });
}
exports.useDateOnOperation = useDateOnOperation;
function useDateOnOrAfterOperation(payload) {
    return (attribute) => ({ isValid: attribute.getTime() >= payload.date.getTime() });
}
exports.useDateOnOrAfterOperation = useDateOnOrAfterOperation;
function useDateOnOrBeforeOperation(payload) {
    return (attribute) => ({ isValid: attribute.getTime() <= payload.date.getTime() });
}
exports.useDateOnOrBeforeOperation = useDateOnOrBeforeOperation;
function useDateBetweenOperation(payload) {
    return (attribute) => ({
        isValid: attribute.getTime() >= payload.start.getTime() && attribute.getTime() <= payload.end.getTime(),
    });
}
exports.useDateBetweenOperation = useDateBetweenOperation;
