import { Component, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core';
import {
  Area,
  ContentApiService,
  DocumentType,
  KeyField
} from '../../services/content-api/content-api.service';
import { FormGroup, Validators } from '@angular/forms';
import { StatusResponse } from '../../models/statusResponse';
import { CurrencyPipe } from '@angular/common';
import { faXmark, faTrash } from '@fortawesome/free-solid-svg-icons';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subject, debounceTime } from 'rxjs';

interface LookupResponse {
  name: string;
  lookupTypeCode: string;
  fieldValues: { fieldCode: string; value: string }[];
}

@Component({
  selector: 'app-key-fields',
  templateUrl: './key-fields.component.html',
  styleUrls: ['./key-fields.component.scss']
})
export class KeyFieldsComponent implements OnInit {
  @Output() changeEvent = new EventEmitter<any>();
  @Output() uploadDocumentEvent = new EventEmitter();
  @Output() cancelFileFilingEvent = new EventEmitter();
  @Output() deleteDocumentEvent = new EventEmitter();
  @Input() docAreas: Area[] = [];
  @Input() filteredDocumentTypes: DocumentType[] = [];
  @Input() currentDocumentType: DocumentType | undefined;
  @Input() docFormGroup!: FormGroup;
  @Input() attachmentStatus: StatusResponse | undefined;
  @Input() showCancelButton: boolean = false;
  @Input() showDeleteDocumentButton: boolean = false;
  @Input() isAutoClassificationEnabled: boolean = false;

  isUploading = false;
  multiValueFields: { [key: string]: string[] } = {};
  faXmark = faXmark;
  faTrash = faTrash;
  lookUpchanged: Subject<KeyField> = new Subject<KeyField>();
  lookupUsersResponse: LookupResponse[] = [];
  lookupSuggestions: { keyFieldCode: string; lookupSuggestion: LookupResponse[] }[] = [];

  constructor(
    private currencyPipe: CurrencyPipe,
    private spinner: NgxSpinnerService,
    private contentService: ContentApiService
  ) {
    this.lookUpchanged.pipe(debounceTime(500)).subscribe(keyField => {
      const inputValue = this.docFormGroup.controls[keyField.code].value;
      // Only perform look up search if there are more than 2 characters typed
      if (inputValue.length >= 2) {
        this.lookupUsers(
          keyField.code,
          keyField.lookupType?.code ?? '',
          this.docFormGroup.controls[keyField.code].value
        );
      }
    });
  }

  /**
   * Lifecycle hook that is called after data-bound properties of a directive are initialized
   * Shows a loading spinner if auto-classification is enabled
   */
  ngOnInit(): void {
    if (this.isAutoClassificationEnabled) {
      this.spinner.show();
    }
  }

  /**
   * Event listener that handles changes in the area and document type fields
   * Emits the `changeEvent` with the event data
   * @param event The change event
   */
  @HostListener('change', ['$event'])
  onChange(event: any): void {
    this.changeEvent.emit(event);
  }

  /**
   * Initiates the document upload process. Shows a loading spinner and adjusts form values for multi-value fields
   * Emits the `uploadDocumentEvent` with the event data
   * @param event The upload event
   */
  uploadDocument(event: any) {
    this.isUploading = true;
    this.spinner.show('documentUploadSpinner');
    Object.keys(this.docFormGroup.controls).forEach(control => {
      if (this.currentDocumentType?.keyfields.some(keyField => keyField.code === control)) {
        const keyField = this.currentDocumentType.keyfields.find(
          keyField => keyField.code === control
        );
        if (keyField?.multivalue) {
          if (this.docFormGroup.controls[control].value !== '') {
            this.addMultipleValue(control);
          }
          this.docFormGroup.controls[control].setValue(this.multiValueFields[control]);
        }
      }
    });
    this.uploadDocumentEvent.emit(event);
    this.multiValueFields = {};
  }

  /**
   * Cancels the document filing process
   * Emits the `cancelFileFilingEvent` with the event data
   * @param event The cancel event
   */
  cancelFileFiling(event: any) {
    this.cancelFileFilingEvent.emit(event);
  }

  /**
   * Completes the upload process by hiding the loading spinner and resetting the upload state
   */
  onUploadComplete() {
    this.isUploading = false;
    this.spinner.hide();
  }

  /**
   * Checks if a specific rule exists within a key field's rules
   * @param keyField The key field to check
   * @param ruleCode The code of the rule to check for
   * @returns {boolean} True if the rule exists, false otherwise
   */
  checkIfRuleExists(keyField: KeyField, ruleCode: string): boolean {
    if (!keyField.rules) {
      return false;
    }

    return keyField.rules.some(rule => rule.code === ruleCode);
  }

  /**
   * Adds a value to a multi-value field
   * @param keyFieldCode The code of the key field to which the value should be added
   */
  addMultipleValue(keyFieldCode: string) {
    const keyFieldValue = this.docFormGroup.controls[keyFieldCode].value;
    if (keyFieldValue !== '') {
      if (!this.multiValueFields[keyFieldCode]) {
        this.multiValueFields[keyFieldCode] = [keyFieldValue];
      } else {
        this.multiValueFields[keyFieldCode].push(this.docFormGroup.controls[keyFieldCode].value);
      }
      this.docFormGroup.controls[keyFieldCode].removeValidators([Validators.required]);
    }

    this.docFormGroup.controls[keyFieldCode].setValue('');
  }

  /**
   * Removes a value from a multi-value field by its index
   * @param keyFieldCode The code of the key field from which the value should be removed
   * @param index The index of the value to be removed
   */
  removeMultipleValue(keyFieldCode: string, index: number) {
    this.multiValueFields[keyFieldCode].splice(index, 1);
    if (this.multiValueFields[keyFieldCode].length === 0) {
      this.docFormGroup.controls[keyFieldCode].setValidators([Validators.required]);
    }
  }

  /**
   * Checks if a specific key field control has any validation errors
   * @param keyFieldCode The code of the key field to check
   * @returns {boolean} True if there are errors, false otherwise
   */
  hasErrors(keyFieldCode: string) {
    return this.docFormGroup.controls[keyFieldCode].errors;
  }

  /**
   * Retrieves the error message text for a specific key field control based on its validation errors
   * @param keyFieldCode The code of the key field to retrieve the error message for
   * @returns {string} The error message text
   */
  errorText(keyFieldCode: string): string {
    if (this.docFormGroup.controls[keyFieldCode].errors?.['required']) {
      return 'Required';
    } else if (this.docFormGroup.controls[keyFieldCode].errors?.['maxlength']) {
      return 'Maximum length exceeded';
    } else if (this.docFormGroup.controls[keyFieldCode].errors?.['pattern']) {
      return 'Invalid format';
    } else if (this.docFormGroup.controls[keyFieldCode].errors?.['min']) {
      return 'Minimum value is ' + this.docFormGroup.controls[keyFieldCode].errors?.['min'].min;
    }
    return '';
  }

  /**
   * Transforms a value to a currency format using the CurrencyPipe
   * @param element The input element containing the value to be transformed
   */
  transformToCurrency(element: any) {
    element.target.value = this.currencyPipe.transform(element.target.value, '$');
  }

  /**
   * Retrieves lookup suggestions for a specific key field
   * @param keyFieldCode The code of the key field to retrieve lookup suggestions for
   * @returns {LookupResponse[]} An array of lookup suggestions
   */
  getLookupSuggestions(keyFieldCode: string): LookupResponse[] {
    let lookupSuggestion = this.lookupSuggestions.find(
      suggestion => suggestion.keyFieldCode === keyFieldCode
    )?.lookupSuggestion;

    if (!lookupSuggestion) {
      lookupSuggestion = [];
    }

    return lookupSuggestion;
  }

  /**
   * Searches for lookup suggestions based on a query
   * @param event The search event containing the query
   * @param keyField The key field to search for
   */
  searchLookup(event: any, keyField: KeyField) {
    const query = event.query || '';
    if (query.length >= 2) {
      this.lookupUsers(keyField.code, keyField.lookupType?.code ?? '', query);
    } else {
      this.lookupSuggestions = [];
    }
  }

  /**
   * Performs a lookup for users based on a query and updates the form with the results
   * @param keyFieldCode The code of the key field to perform the lookup for
   * @param lookupTypeCode The code of the lookup type to use
   * @param query The query string to search for
   * @param isInitialLookup Whether this is the initial lookup (optional, default is false)
   */
  lookupUsers(
    keyFieldCode: string,
    lookupTypeCode: string,
    query: string,
    isInitialLookup = false
  ): void {
    this.contentService.lookupUser(query, lookupTypeCode).subscribe({
      next: response => {
        this.lookupUsersResponse = response;
        const lookupSuggestion = this.lookupSuggestions.find(
          suggestion => suggestion.keyFieldCode === keyFieldCode
        );

        if (lookupSuggestion) {
          lookupSuggestion.lookupSuggestion = response;
        } else {
          this.lookupSuggestions.push({
            keyFieldCode: keyFieldCode,
            lookupSuggestion: response
          });
        }

        if (isInitialLookup && this.lookupUsersResponse.length > 0) {
          if (this.lookupUsersResponse.length >= 1) {
            const firstResult = this.lookupUsersResponse[0];
            this.docFormGroup.controls[keyFieldCode].setValue(firstResult);

            // Update the value and status of the form control to finalize the change
            this.docFormGroup.updateValueAndValidity();
          }
        }
      },
      error: error => {
        console.error(error);
        this.lookupUsersResponse = [];
        this.lookupSuggestions = [];
      }
    });
  }

  /**
   * Deletes a document
   * Emits the `deleteDocumentEvent` with the event data
   */
  deleteDocument() {
    this.deleteDocumentEvent.emit();
  }
}
