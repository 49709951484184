<ngx-dropzone
  class="dropzone"
  (change)="onSelect($event)"
>
  <ngx-dropzone-label>
    <img
      src="../../../assets/cloud-arrow-up-regular.svg"
      class="upload-icon"
      alt="Upload Icon"
    />
    <p class="drag-and-drop-text">{{ 'dragAndDrop' | translate }}</p>
    <p class="description-text">{{ 'dragAndDropDescription' | translate }}</p>
  </ngx-dropzone-label>
</ngx-dropzone>
<div class="separator">
  <div class="separator-line"></div>
  <span class="separator-text">{{ 'or' | translate }}</span>
  <div class="separator-line"></div>
</div>
<div class="buttons-container">
  <div class="dropzone-button">
    <ngx-dropzone (change)="onSelect($event)">
      <fa-icon [icon]="faFolder"> </fa-icon>
      <p>{{ 'selectFiles' | translate }}</p>
    </ngx-dropzone>
  </div>
</div>
