<div class="container">
  <div class="file-seletion-header">
    <div class="head-label">
      <span>{{ batchName ? batchName : (attachmentsTitle | translate) }}</span>
      <div class="batch-options">
        <fa-icon
          class="batch-options-icon"
          *ngIf="enableBatchOptions"
          [icon]="faEllipsis"
          (click)="toggleBatchOptions()"
        ></fa-icon>
        <div
          class="batch-options-container"
          *ngIf="showBatchOptions"
        >
          <span
            class="batch-option"
            (click)="openEditBatch()"
          >
            <fa-icon
              class="blue-icon"
              [icon]="faPenToSquare"
            ></fa-icon>
            {{ 'editBatch' | translate }}
          </span>
          <span
            class="batch-option"
            (click)="openAddDocuments()"
          >
            <img
              src="../../../assets/file-plus-solid.svg"
              class="add-documents-icon"
              alt="Add Documents Icon"
            />
            {{ 'addDocuments' | translate }}
          </span>
          <span
            class="batch-option"
            (click)="deleteBatch()"
          >
            <fa-icon
              class="red-icon"
              [icon]="faTrash"
            ></fa-icon>
            {{ 'deleteBatch' | translate }}
          </span>
        </div>
      </div>
    </div>
  </div>
  <div class="files-container">
    <div *ngIf="attachments.length === 0 && isAddIn()">
      <span class="no-attachments-label">{{ 'noAttachments' | translate }}</span>
    </div>
    <div *ngIf="attachments.length === 0 && !isLoadingFiles && isWebCapture()">
      <span class="no-attachments-label">{{ 'noBatchDocs' | translate }}</span>
    </div>
    <div class="files-spinner-container">
      <ngx-spinner
        color="#006CFA"
        bdColor="rgba(255,255,255, 0.3)"
        [fullScreen]="false"
        type="ball-spin-clockwise"
        size="small"
        [showSpinner]="
          (isPrinter() && attachments.length === 0) || (isLoadingFiles && attachments.length === 0)
        "
        name="loadingFilesSpinner"
      ></ngx-spinner>
    </div>
    <div
      class="file-option-container"
      *ngFor="let file of attachments; let i = index; trackBy: trackByIndex"
    >
      <div
        class="file-option"
        [class.disabled]="file.completed"
        (click)="!file.completed && selectAttachment(i)"
        [ngClass]="{ 'active-file': currentAttachmentIndex === i }"
        tabindex="0"
      >
        <span>
          {{ file.name }}
        </span>
        <div class="check-container">
          <img
            *ngIf="file.completed"
            class="check"
            src="../../../assets/check.svg"
            alt="check"
          />
        </div>
        <div
          class="spinner-container"
          *ngIf="!file.status && file.classificationStarted"
        >
          <ngx-spinner
            color="#006CFA"
            bdColor="rgba(255,255,255, 0.3)"
            [fullScreen]="false"
            [showSpinner]="!file.status"
            type="ball-spin-clockwise"
            size="small"
          ></ngx-spinner>
        </div>
      </div>
    </div>
  </div>
  <div class="close-batch-container">
    <button
      class="close-batch-button"
      *ngIf="enableBatchOptions"
      (click)="closeBatch()"
    >
      {{ 'closeBatch' | translate }}
    </button>
  </div>
</div>
