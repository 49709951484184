"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.evaluatePlan = void 0;
const rules_1 = require("../rules");
function evaluatePlan(plan, attributes) {
    const treatableRule = findTreatableRule(plan, attributes);
    if (treatableRule) {
        return { treatment: treatableRule.treatment };
    }
    return { treatment: plan.defaultTreatment };
}
exports.evaluatePlan = evaluatePlan;
function findTreatableRule(plan, attributes) {
    var _a;
    return (_a = plan.rules) === null || _a === void 0 ? void 0 : _a.find((rule) => {
        const evaluator = (0, rules_1.createRuleEvaluator)({ rule });
        const result = evaluator(attributes);
        return result === rules_1.RuleEvaluationResultEnum.Treatable;
    });
}
