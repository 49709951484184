"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.evaluateIsEntitledToFeature = void 0;
const types_1 = require("../types");
const utils_1 = require("../utils");
const evaluators_1 = require("./evaluators");
function evaluateIsEntitledToFeature(featureKey, userEntitlementsContext, attributes = {}) {
    if (!userEntitlementsContext) {
        return { isEntitled: false, justification: types_1.NotEntitledJustification.MISSING_FEATURE };
    }
    const entitlementResults = [];
    for (const evaluator of (0, evaluators_1.getIsEntitledEvaluators)()) {
        entitlementResults.push(evaluator(featureKey, userEntitlementsContext, attributes));
        if (!(0, utils_1.shouldContinue)(entitlementResults)) {
            break;
        }
    }
    return (0, utils_1.getResult)(entitlementResults);
}
exports.evaluateIsEntitledToFeature = evaluateIsEntitledToFeature;
