import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { BatchService } from '../../../services/batch/batch.service';
import {
  Area,
  ContentApiService,
  DocumentType
} from '../../../services/content-api/content-api.service';
import { TenantApiService } from '../../../services/tenant-api/tenant-api.service';
import { switchMap } from 'rxjs';

@Component({
  selector: 'app-batch-settings',
  templateUrl: './batch-settings.component.html',
  styleUrl: './batch-settings.component.scss'
})
export class BatchSettingsComponent implements OnInit {
  @Input() batchFormGroup: FormGroup = this.fb.group({
    batchId: [''],
    name: [''],
    areas: [''],
    defaultDocumentType: ['']
  });
  @Input() tenantId: string = '';

  documentTypes: DocumentType[] = [];
  areas: Area[] = [];
  filteredDocumentTypes: DocumentType[] = [];

  constructor(
    private fb: FormBuilder,
    private contentService: ContentApiService,
    private tenantService: TenantApiService
  ) {}

  ngOnInit(): void {
    this.tenantService
      .getTenantUrls(this.tenantId)
      .pipe(
        switchMap(tenantUrls => {
          this.contentService.setBaseUrl(tenantUrls.contentApiUrl);
          return this.contentService.getDocumentTypes();
        }),
        switchMap(documentTypes => {
          this.documentTypes = documentTypes;
          return this.contentService.getAreas();
        })
      )
      .subscribe(areas => {
        this.areas = areas;
        this.batchFormGroup.get('areas')?.valueChanges.subscribe(areas => {
          this.filteredDocumentTypes = [];
          areas.forEach((area: Area) => {
            this.filteredDocumentTypes.push(
              ...this.documentTypes.filter(documentType => documentType.areaCode === area.code)
            );
          });
        });
      });
  }
}
