import { Component, Input, Output, EventEmitter } from '@angular/core';
import { faXmark } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-classification-results',
  templateUrl: './classification-results.component.html',
  styleUrl: './classification-results.component.scss'
})
export class ClassificationResultsComponent {
  @Input() currentMostConfidentDocument: any;
  @Output() closeResultsEvent = new EventEmitter();
  faXmark = faXmark;

  closeResults(): void {
    this.closeResultsEvent.emit();
  }
}
