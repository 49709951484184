"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useOperation = void 0;
const string_1 = require("../string");
const numeric_1 = require("../numeric");
const date_1 = require("../date");
const boolean_1 = require("../boolean");
const operationEnrichersMapper = {
    ...string_1.StringOperationsMapper,
    ...numeric_1.NumericOperationsMapper,
    ...date_1.DateOperationsMapper,
    ...boolean_1.BooleanOperationsMapper,
};
function useOperation(operation, value) {
    const operationContextEnricher = operationEnrichersMapper[operation];
    return operationContextEnricher ? operationContextEnricher(value) : undefined;
}
exports.useOperation = useOperation;
