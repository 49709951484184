"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.directEntitlementEvalutor = void 0;
const types_1 = require("../../types");
function directEntitlementEvalutor(featureKey, userEntitlementsContext, attributes = {}) {
    const feature = userEntitlementsContext.features[featureKey];
    let hasExpired = false;
    if (feature && feature.expireTime !== null) {
        hasExpired = feature.expireTime !== types_1.NO_EXPIRATION_TIME && feature.expireTime < Date.now();
        if (!hasExpired) {
            return { isEntitled: true };
        }
    }
    return {
        isEntitled: false,
        justification: hasExpired ? types_1.NotEntitledJustification.BUNDLE_EXPIRED : types_1.NotEntitledJustification.MISSING_FEATURE,
    };
}
exports.directEntitlementEvalutor = directEntitlementEvalutor;
