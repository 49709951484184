import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, catchError, throwError } from 'rxjs';
import { DISABLE_AUTH_INTERCEPTOR } from '../../http-interceptors/auth-interceptor';

@Injectable({
  providedIn: 'root'
})
export class DocumentManagementService {
  constructor(private http: HttpClient) {}

  getDocument(contentUri: string): Observable<any> {
    const context = new HttpContext().set(DISABLE_AUTH_INTERCEPTOR, true);
    return this.http.get(contentUri, { responseType: 'arraybuffer', context }).pipe(
      catchError(error => {
        console.error('Error fetching document:', error.message);
        return throwError(() => new Error('Error fetching document'));
      })
    );
  }
}
