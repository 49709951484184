"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.NumericSanitizersMapper = exports.sanitizeNumericRange = exports.sanitizeSingleNumber = void 0;
const types_1 = require("../types");
const isNumber = (value) => typeof value === 'number';
const sanitizeSingleNumber = (value) => {
    const sanitizedValue = value.number !== undefined && isNumber(value.number) ? { number: value.number } : undefined;
    return {
        isSanitized: !!sanitizedValue,
        sanitizedValue,
    };
};
exports.sanitizeSingleNumber = sanitizeSingleNumber;
const sanitizeNumericRange = (value) => {
    const sanitizedValue = value.start !== undefined && value.end !== undefined && isNumber(value.start) && isNumber(value.end)
        ? { start: value.start, end: value.end }
        : undefined;
    return {
        isSanitized: !!sanitizedValue,
        sanitizedValue,
    };
};
exports.sanitizeNumericRange = sanitizeNumericRange;
exports.NumericSanitizersMapper = {
    [types_1.OperationEnum.Equal]: exports.sanitizeSingleNumber,
    [types_1.OperationEnum.GreaterThan]: exports.sanitizeSingleNumber,
    [types_1.OperationEnum.GreaterThanEqual]: exports.sanitizeSingleNumber,
    [types_1.OperationEnum.LesserThan]: exports.sanitizeSingleNumber,
    [types_1.OperationEnum.LesserThanEqual]: exports.sanitizeSingleNumber,
    [types_1.OperationEnum.BetweenNumeric]: exports.sanitizeNumericRange,
};
