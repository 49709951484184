"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.featureFlagEvaluator = void 0;
const types_1 = require("../../types");
const feature_flags_1 = require("../../../feature-flags");
const attributes_utils_1 = require("../../utils/attributes.utils");
const rules_1 = require("../../../rules");
function featureFlagEvaluator(featureKey, userEntitlementsContext, attributes = {}) {
    const feature = userEntitlementsContext.features[featureKey];
    if (feature && feature.featureFlag) {
        const preparedAttributes = (0, attributes_utils_1.prepareAttributes)(attributes);
        const { treatment } = (0, feature_flags_1.evaluateFeatureFlag)(feature.featureFlag, preparedAttributes);
        if (treatment === rules_1.TreatmentEnum.True) {
            return { isEntitled: true };
        }
    }
    return {
        isEntitled: false,
        justification: types_1.NotEntitledJustification.MISSING_FEATURE,
    };
}
exports.featureFlagEvaluator = featureFlagEvaluator;
