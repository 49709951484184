"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useSanitizer = void 0;
const boolean_1 = require("../boolean");
const sanitizers_1 = require("../string/sanitizers");
const sanitizers_2 = require("../date/sanitizers");
const numeric_1 = require("../numeric");
const SanitizersMapper = {
    ...sanitizers_1.StringSanitizersMapper,
    ...numeric_1.NumericSanitizersMapper,
    ...sanitizers_2.DateSanitizersMapper,
    ...boolean_1.BooleanSanitizersMapper,
};
function useSanitizer(operation) {
    return SanitizersMapper[operation];
}
exports.useSanitizer = useSanitizer;
