import {
  Component,
  Input,
  Output,
  EventEmitter,
  OnInit,
  ViewChild,
  ElementRef
} from '@angular/core';
import { BatchResponse, ListBatchesParams } from '../../models/sdk-interfaces';
import { BatchService } from '../../services/batch/batch.service';
import { BatchStatus } from '../../constants';

interface BatchColumn {
  field: string;
  header: string;
}

@Component({
  selector: 'app-batch-list',
  templateUrl: './batch-list.component.html',
  styleUrls: ['./batch-list.component.scss']
})
export class BatchListComponent implements OnInit {
  @Input() tenantId: string = '';
  @Output() openBatchEvent = new EventEmitter<string>();

  @ViewChild('tableContainer', { static: true }) tableContainer!: ElementRef;

  batches: BatchResponse[] = [];
  cols: BatchColumn[] = [];
  isLoading: boolean = false;
  offset: number = 0;
  count: number = 25;
  hasMoreData: boolean = true;

  filters: { [key: string]: string } = {};
  globalFilterValue: string = '';

  currentTab: string = 'available';

  constructor(private batchService: BatchService) {}

  ngOnInit(): void {
    this.cols = [
      { field: 'batchId', header: 'id' },
      { field: 'name', header: 'batchName' },
      { field: 'createdBy', header: 'batchCreator' },
      { field: 'createdAt', header: 'creationDate' },
      { field: 'status', header: 'status' },
      { field: 'filingProgress', header: 'filingProgress' }
    ];
    this.loadData();
  }

  filterByTab(tab: string): void {
    this.currentTab = tab;

    let status: string | undefined;

    switch (tab) {
      case 'available':
        status = `${BatchStatus.Available}`;
        break;
      case 'completed':
        status = `${BatchStatus.Completed}`;
        break;
      default:
        status =
          BatchStatus[(tab.charAt(0).toUpperCase() + tab.slice(1)) as keyof typeof BatchStatus];
        break;
    }

    this.resetData(status);
  }

  resetData(status?: string): void {
    this.batches = [];
    this.offset = 0;
    this.hasMoreData = true;
    this.loadData(status);
  }

  loadData(status?: string): void {
    if (this.isLoading || !this.hasMoreData) {
      return;
    }

    this.isLoading = true;

    const params: ListBatchesParams = {
      offset: this.offset,
      count: this.count,
      status: status || this.getCurrentStatusFilter()
    };

    this.batchService
      .listBatches(this.tenantId, params)
      .then(response => {
        const newBatches = response.items;

        if (newBatches.length < this.count) {
          this.hasMoreData = false; // No more data to load
        }

        this.batches = [...this.batches, ...newBatches];
        this.offset += this.count;
        this.isLoading = false;
      })
      .catch(error => {
        console.error('Error fetching batches:', error);
        this.isLoading = false;
      });
  }

  getFieldValue(batch: BatchResponse, field: string): any {
    if (field in batch) {
      return (batch as any)[field];
    }
    return '';
  }

  getCurrentStatusFilter(): string | undefined {
    switch (this.currentTab) {
      case 'active':
        return `${BatchStatus.Available},${BatchStatus.CheckedOut}`;
      case 'all':
        return undefined;
      default:
        return BatchStatus[
          (this.currentTab.charAt(0).toUpperCase() +
            this.currentTab.slice(1)) as keyof typeof BatchStatus
        ];
    }
  }

  getFilingProgress(batch: BatchResponse): string {
    const documentCount = batch.statistics?.documentCount || 0;
    const unprocessedDocumentCount = batch.statistics?.unprocessedDocumentCount || 0;
    const processedDocCount = documentCount - unprocessedDocumentCount;
    return `${processedDocCount}/${documentCount}`;
  }

  onScroll(): void {
    const element = this.tableContainer.nativeElement;
    if (element.scrollHeight - element.scrollTop === element.clientHeight) {
      this.loadData();
    }
  }

  openBatch(batchId: string | undefined): void {
    console.log('Opening batch', batchId);
    this.openBatchEvent.emit(batchId);
  }

  getStatusBadgeClass(status: string | undefined): string {
    if (!status) {
      return 'status-badge';
    }

    switch (status.toLowerCase()) {
      case 'available':
      case 'checked_out':
        return 'status-badge available';
      case 'draft':
        return 'status-badge draft';
      case 'deleted':
        return 'status-badge deleted';
      case 'completed':
        return 'status-badge completed';
      default:
        return 'status-badge';
    }
  }
}
