import { Injectable, OnDestroy } from '@angular/core';
import { environment } from '../../environments/environment';
import * as LaunchDarkly from 'launchdarkly-js-client-sdk';
import { LDFlagValue, LDSingleKindContext } from 'launchdarkly-js-client-sdk';
import { Observable, BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class FeatureFlagService implements OnDestroy {
  private client!: LaunchDarkly.LDClient;
  private initialized = false;

  constructor() {}

  /**
   * Cleans up resources when the service is destroyed
   * Closes the LaunchDarkly client if it exists
   */
  ngOnDestroy(): void {
    if (this.client) {
      this.client.close();
    }
  }

  /**
   * Initializes the LaunchDarkly client anonymously or with provided user context
   * If `key` and `name` are provided, the user is not anonymous
   *
   * @param {Object} userContext - The user context for LaunchDarkly initialization
   * @param {string} [userContext.key] - The unique key for the user (optional for anonymous users)
   * @param {string} [userContext.name] - The name of the user (optional for anonymous users)
   * @param {string} userContext.tenantId - The tenant ID associated with the user
   */
  initialize(userContext: { key?: string; name?: string; tenantId: string }): Promise<void> {
    const context: LDSingleKindContext = {
      kind: 'user',
      anonymous: !userContext.key || !userContext.name,
      tenantId: userContext.tenantId
    };

    if (userContext.key && userContext.name) {
      context.key = userContext.key;
      context.name = userContext.name;
    }

    this.client = LaunchDarkly.initialize(environment.launchDarkly.clientSideId, context);

    return this.client
      .waitUntilReady()
      .then(() => {
        this.initialized = true;
      })
      .catch(error => {
        console.error('Feature flag service initialization failed:', error);
        throw error;
      });
  }

  /**
   * Updates the LaunchDarkly context after user identification (login)
   * This is used to switch from anonymous to identified user.
   *
   * @param {Object} userContext - The identified user context to update
   * @param {string} userContext.key - The unique key for the user
   * @param {string} userContext.name - The name of the user
   * @param {string} userContext.tenantId - The tenant ID associated with the user
   */
  identify(userContext: { key: string; name: string; tenantId: string }): void {
    if (this.client && this.initialized) {
      const newContext: LDSingleKindContext = {
        kind: 'user',
        key: userContext.key,
        name: userContext.name,
        tenantId: userContext.tenantId,
        anonymous: false
      };

      this.client
        .identify(newContext)
        .then(() => {
          console.log('LaunchDarkly context updated to identified user');
        })
        .catch(error => {
          console.error('Failed to identify user in LaunchDarkly:', error);
        });
    }
  }

  /**
   * Retrieves the value of a feature flag
   * Returns an observable that emits the current value of the flag and updates when the flag value changes
   *
   * @param {string} flagKey - The key of the feature flag to retrieve
   * @param {LDFlagValue} [defaultValue=false] - The default value to return if the flag is not available
   * @returns {Observable<LDFlagValue>} - An observable that emits the value of the feature flag
   */
  getFlag(flagKey: string, defaultValue: LDFlagValue = false): Observable<LDFlagValue> {
    const flagValue$ = new BehaviorSubject<LDFlagValue>(defaultValue);

    if (this.initialized) {
      flagValue$.next(this.client.variation(flagKey, defaultValue));
    } else {
      this.client.waitUntilReady().then(() => {
        flagValue$.next(this.client.variation(flagKey, defaultValue));
      });
    }

    this.client.on(`change:${flagKey}`, newFlagValue => {
      flagValue$.next(newFlagValue);
    });

    return flagValue$.asObservable();
  }
}
