"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.NO_EXPIRATION_TIME = exports.NotEntitledJustification = void 0;
var NotEntitledJustification;
(function (NotEntitledJustification) {
    NotEntitledJustification["MISSING_FEATURE"] = "MISSING_FEATURE";
    NotEntitledJustification["MISSING_PERMISSION"] = "MISSING_PERMISSION";
    NotEntitledJustification["BUNDLE_EXPIRED"] = "BUNDLE_EXPIRED";
})(NotEntitledJustification = exports.NotEntitledJustification || (exports.NotEntitledJustification = {}));
exports.NO_EXPIRATION_TIME = -1;
