import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { BatchService } from '../../services/batch/batch.service';
import { FileUploadStatus } from '../file-uploader/file-uploader.component';
import {
  BatchDocumentRequest,
  BulkCreateBatchDocumentRequest,
  CreateBatchRequest
} from '../../models/sdk-interfaces';
import {
  faCircleCheck,
  faExclamationCircle,
  faCaretDown,
  faCaretUp
} from '@fortawesome/free-solid-svg-icons';
import { NgxSpinnerService } from 'ngx-spinner';
import { Area } from '../../services/content-api/content-api.service';

@Component({
  selector: 'app-batch-creation',
  templateUrl: './batch-creation.component.html',
  styleUrls: ['./batch-creation.component.scss']
})
export class BatchCreationComponent implements OnInit {
  @Input() tenantId!: string;
  @Input() userId!: string;

  @Output() cancelBatchCreationEvent = new EventEmitter();
  @Output() hasUploadedFilesEvent = new EventEmitter();

  batchFormGroup!: FormGroup;
  uploadStatuses: FileUploadStatus[] = [];
  isExpanded: boolean = true;
  fileIdsToAssociate: string[] = [];

  faCircleCheck = faCircleCheck;
  faExclamationCircle = faExclamationCircle;
  faCaretDown = faCaretDown;
  faCaretUp = faCaretUp;

  constructor(
    private fb: FormBuilder,
    private batchService: BatchService,
    private spinner: NgxSpinnerService
  ) {}

  ngOnInit(): void {
    this.batchFormGroup = this.fb.group({
      batchId: [''],
      name: [''],
      areas: [''],
      defaultDocumentType: ['']
    });

    this.createBatchId();
  }

  createBatchId() {
    this.batchService.createBatchId(this.tenantId).then(response => {
      console.log('Batch ID created', response);
      this.batchFormGroup.controls['batchId'].setValue(response.batchId);
      this.batchFormGroup.controls['batchId'].disable();
    });
  }

  createBatch() {
    if (this.fileIdsToAssociate.length > 0) {
      const batchData: CreateBatchRequest = {
        ...this.batchFormGroup.value,
        batchId: this.batchFormGroup.controls['batchId'].value,
        status: 'available',
        areas: this.batchFormGroup.controls['areas'].value
          ? this.batchFormGroup.controls['areas'].value.map((area: Area) => area.code)
          : ''
      };
      this.batchService.createBatch(this.tenantId, this.userId, batchData).then(response => {
        console.log('Batch created', response);

        console.log('Document IDs to associate', this.fileIdsToAssociate);
        const bulkCreateRequest: BulkCreateBatchDocumentRequest = {
          documents: []
        };

        this.fileIdsToAssociate.forEach(fileId => {
          const batchDocument: BatchDocumentRequest = {
            documentId: fileId,
            source: 'document'
          };
          bulkCreateRequest.documents.push(batchDocument);
        });

        this.batchService
          .bulkCreateBatchDocuments(
            this.tenantId,
            this.userId,
            batchData.batchId!,
            bulkCreateRequest
          )
          .then(response => {
            console.log('Files associated to batch', response);
            this.cancelBatchCreation();
          })
          .catch(error => {
            console.error('Error associating files to batch', error);
          });
      });
    } else {
      console.error('No files to associate to batch');
    }
  }

  cancelBatchCreation() {
    this.cancelBatchCreationEvent.emit();
  }

  /**
   * Handles the upload status emitted from the FileUploaderComponent
   * @param status - The current status of the file uploads
   */
  onUploadStatusUpdate(status: FileUploadStatus[]): void {
    this.uploadStatuses = status;
  }

  /**
   * Toggles the expanded state of the upload status section
   */
  toggleUploadStatus(): void {
    this.isExpanded = !this.isExpanded;
  }

  /**
   * Returns the number of files that have been successfully uploaded
   */
  uploadedCount(): number {
    return this.uploadStatuses.filter(file => file.status === 'completed').length;
  }

  setFileIdsToAssociate(fileIds: string[]) {
    this.hasUploadedFilesEvent.emit();
    fileIds.forEach(fileId => {
      this.fileIdsToAssociate.push(fileId);
    });

    console.log('File IDs to associate', this.fileIdsToAssociate);
  }
}
